export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/lists/search": [7,[2]],
		"/(auth)/login": [13,[3]],
		"/(app)/movies": [8,[2]],
		"/(app)/movies/category/[category]": [10,[2]],
		"/(app)/movies/search": [11,[2]],
		"/(app)/movies/[id]": [9,[2]],
		"/(auth)/register": [14,[3]],
		"/(app)/users/search": [12,[2]],
		"/(app)/[username]": [5,[2]],
		"/(app)/[username]/list/[list_id]": [6,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';